<template>
  <div>
    <b-modal
      id="modal-2"
      title="Información"
      ok-only
      ok-title="Aceptar"
      no-close-on-esc
      no-close-on-backdrop
      header-bg-variant="dark"
      header-text-variant="light"
    >
      <p style="font-size: 1rem">
        Lo invitamos a que programe la toma de muestras a domicilio a través de
        Nuestra agente virtual Angela o por nuestra página web
        <a
          href="https://www.alianzadiagnostica.com/usuarios/toma-de-muestras-a-domicilio"
          target="blank"
          >https://www.alianzadiagnostica.com</a
        >
      </p>
    </b-modal>
    <b-modal
      hide-header-close
      id="modal-4"
      title="Error confirmando reserva"
      ok-only
      ok-title="Cerrar"
      no-close-on-esc
      no-close-on-backdrop
      header-bg-variant="dark"
      header-text-variant="light"
      @ok="hideModalConfirmation()"
    >
      <p style="font-size: 1rem">
        Ha ocurrido un error al confirmar la reserva. Por favor ingrese de nuevo los datos o intente más tarde.
      </p>
    </b-modal>
    <b-modal
      id="modal-3"
      title="Reserva Exitosa"
      no-close-on-esc
      no-close-on-backdrop
      header-bg-variant="dark"
      header-text-variant="light"
      ref="modal-confirmation"
      @close="hideModalConfirmation()"
    >
      <p class="text-justify">
        Reserva confirmada en la sede
        <b>{{ confirmDataReserve.placeName }}</b
        >, para el día
        <b>{{ this.$moment(confirmDataReserve.date).locale("es-mx").format("LL") }}</b>
        en el horario de <b>{{ confirmDataReserve.schedule }}</b>
      </p>
      <p class="text-justify" style="font-size: 1rem; color: red;">
            <b>Le recordamos que deberá asistir a su toma de muestra máximo 10 minutos antes de la hora asignada y el tiempo de atención estimado es de 30 minutos</b>
          </p>
      <p class="text-justify" style="font-size: 1rem">
        Señor usuario, recuerde llegar a la hora de su <span style="font-weight: bolder">cita asignada</span>, para mantener el distanciamiento social y el cumplimiento de las medidas de la emergencia sanitaria Covid 19.
      </p>
      <p class="text-justify" style="font-size: 1rem">
        Recuerde presentar en físico los siguientes documentos:
        <br>
        <ul>
          <li>Documento de identidad</li>
          <li>Orden médica (Si aplica)</li>
          <li>Autorización médica vigente (Si aplica)</li>
          <li>Historia Clínica (Si aplica)</li>
        </ul>
      </p>
      <p class="text-justify" style="font-size: 1.1rem; color: red;">
        <b>Importante:</b> Por favor consulte la preparación de sus exámenes de laboratorio clínico en el siguiente enlace <a target="_blank" href="https://www.alianzadiagnostica.com/examenes">https://www.alianzadiagnostica.com/examenes</a>
      </p>
      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="primary" @click="hideModalConfirmation()">
          Aceptar
        </b-button>
      </template>
      
    </b-modal>
    <b-container v-if="!errorReserve">
      <b-row class="mt-2">
        <b-col style="font-size: 1rem">
          <p class="text-justify">
            {{ nomUser }}, se realizará reserva en la sede
            <b>{{ placeAdress }}</b
            >, para el día
            <b>{{ this.$moment(dateSelected).locale("es-mx").format("LL") }}</b>
            en el horario de <b>{{ scheduleSelected }}</b>
          </p>
          <p class="text-justify">
            <b>Le recordamos que deberá asistir a su toma de muestra máximo 10 minutos antes de la hora asignada y el tiempo de atención estimado es de 30 minutos</b>
          </p>
          <p class="text-justify" v-if="showPreguntas">
            Para finalizar marque <b>Si</b>, <b>No</b> o llene los valores en
            las siguientes preguntas y de click en el botón Confirmar
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-icon
          icon="arrow-clockwise"
          animation="spin"
          font-scale="4"
          v-if="isBussy"
        ></b-icon>
        </b-col>
      </b-row>
      <b-row v-if="showPreguntas">
        <b-col>
          <b-table
            hover
            bordered
            :items="preguntas"
            small
            table-variant="primary"
            head-variant="dark"
            :fields="fields"
          >
            <template #cell(text)="data">
              <p class="text-justify" style="font-size: 1rem">
                {{ data.value }}
              </p>
            </template>
            <template #cell(respuesta)="data">
              <b-container v-if="data.item.id == '8'" class="mt-1">
                <b-row>
                  <b-col>
                    <b-form-input
                      v-model="rta8"
                      @blur="marcarrta8(data.item.id - 1)"
                      placeholder="Enter your name"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
              <b-container v-if="data.item.id == '11'" class="mt-1">
                <b-row>
                  <b-col>
                    <b-form-input
                      v-model="rta11"
                      @blur="marcarrta11(data.item.id - 1)"
                      placeholder="Enter your name"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
              <b-container v-if="data.item.id != 11 && data.item.id != 8">
                <b-row>
                  <b-col>
                    <b-button
                      @click="marcar(data.item.id - 1, 'Si')"
                      :variant="
                        data.item.respuesta == 'Si' ? 'dark' : 'outline-dark'
                      "
                      class="mt-2"
                      block
                      style="font-size: 0.8rem"
                      >Si</b-button
                    >
                  </b-col>
                  <b-col>
                    <b-button
                      @click="marcar(data.item.id - 1, 'No')"
                      :variant="
                        data.item.respuesta == 'No' ? 'dark' : 'outline-dark'
                      "
                      class="mt-2"
                      block
                      style="font-size: 0.8rem"
                      >No</b-button
                    >
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            :pressed="true"
            type="button"
            variant="primary"
            @click="goStepSchedule"
            style="font-size: 0.8rem"
            block
          >
            <b-icon
              icon="clock-history"
              aria-hidden="true"
              class="mr-2"
            ></b-icon>
            Cambiar Horario</b-button
          >
        </b-col>
        <b-col>
          <b-button
            :pressed="true"
            type="button"
            variant="primary"
            @click="goFinish"
            style="font-size: 0.8rem"
            block
          >
            <b-icon icon="check" aria-hidden="true" class="mr-2"></b-icon>
            Confirmar</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "StepConfirmReserve",
  components: {},
  data() {
    return {
      selected: "",
      fields: [
        { key: "text", label: "Pregunta" },
        { key: "respuesta", label: "Respuesta" },
      ],
      options: [
        { text: "Si", value: false },
        { text: "No", value: false },
      ],
      rta8: 0,
      rta11: 0,
      isBussy: false,
      showPreguntas: true,
      errorReserve: false
    };
  },
  computed: {
    ...mapState("users", ["userData", "preguntas"]),
    ...mapState("reserves", [
      "idReserve",
      "dateSelected",
      "scheduleSelected",
      "schedules",
      "placeSelected",
      "esFrotisCultivo",
      "confirmDataReserve"
    ]),
    ...mapState("places", ["places"]),
    nomUser() {
      return this.userData.name + " " + this.userData.lastname;
    },
    placeAdress() {
      return this.placeSelected ?? (
        this.placeSelected +
        " (" +
        this.places.filter((el) => el.name == this.placeSelected)[0].adress +
        ")"
      );
    },
    reservaConfirmada(){
      return this.confirmDataReserve;
    }
  },
  mounted() {
    if (this.userData.document == "") {
      this.$router.replace("/");
    }
  },
  methods: {
    ...mapActions("users", ["resetUserData", "createUser", "updateUser"]),
    ...mapActions("reserves", [
      "addReserve",
      "updateReserve",
      "resetReserveData",
      "getAvailabilityReserves",
      "onReserveFail"
    ]),
    async goFinish() {
      //Recorremos las preguntas y si alguna no se ha contestado no se deja seguir
      let seguir = true;
      this.preguntas.forEach((el) => {
        if (el.respuesta == "" && el.id != "8") {
          seguir = false;
        }
      });

      if (!seguir) {
        this.$toastr.e(
          "Debe completar todas las respuestas",
          "Confirmación de Reserva"
        );
        return;
      }

      let algunSi = false;
      this.preguntas.forEach((el) => {
        if (el.respuesta == "Si" && el.id != "8") {
          algunSi = true;
        }
      });

      if (algunSi) {
        this.$bvModal.show("modal-2");
        return;
      }

      await this.getAvailabilityReserves();
      let scheduleDisponible = this.schedules.filter((e) => {
        if (e.schedule == this.scheduleSelected) {
          return e;
        }
      });

      if (scheduleDisponible[0].state <= 0) {
        this.$toastr.e(
          "Se ha agotado la disponibilidad para la fecha y horario seleccionados. Puede seleccionar otra fecha u horario.",
          "Confirmación de Reserva"
        );
        return;
      }
      //Si se tiene Id de Reserva es porque se está modificando, si  no se agrega una nueva
      this.isBussy = true;
      this.showPreguntas = false;
      if (this.idReserve == "") {
        this.addReserve().then(async (rta) => {
          if(rta){
            this.showModal();
            await this.createUser();
          }else{
            this.errorReserve = true
            await this.showModalNoReserve();
            // this.$router.replace("/");
          }
        }).catch(async (e) => {
          this.errorReserve = true
          await this.showModalNoReserve();
          // this.$router.replace("/");
        })
        // if (this.esFrotisCultivo) {
        //   this.addReserve();
        // }
      } else {
        this.updateReserve().then(async () => {
          this.showModal();
          await this.updateUser();    
        }).catch(async (e) => {
          this.errorReserve = true
          await this.showModalNoReserve();
          // this.$router.replace("/");
        })
        //Preguntar si se puede cambiar datos del usuario
      }
    },
    showModal(){
      this.isBussy = false;
      this.$bvModal.show("modal-3");
    },
    showModalNoReserve(){
      this.isBussy = false;
      this.$bvModal.show("modal-4");
    },
    goStepSchedule() {
      //Vamos al paso anterior
      this.$router.go(-1);
    },
    marcar(id, rta) {
      //Marcamos la respuesta a la pregunta
      this.preguntas[id].respuesta = rta;
    },
    marcarrta8(id) {
      //Marcamos la respuesta a la pregunta
      this.preguntas[id].respuesta = this.rta8;
    },
    marcarrta11(id) {
      //Marcamos la respuesta a la pregunta
      this.preguntas[id].respuesta = this.rta11;
    },
    hideModalConfirmation(){
      //Vamos al inicio
      //Limpiamos el store
      this.resetReserveData();
      this.resetUserData();
      this.$refs['modal-confirmation'].hide()
      this.$router.replace("/");
    }
  },
};
</script>

<style lang="scss">
.fz-12 {
  font-size: 10px;
  font-weight: bolder;
}
</style>